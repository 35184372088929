import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-clickable-x',
  templateUrl: './clickable-x.component.html',
  styleUrls: ['./clickable-x.component.scss']
})
export class ClickableXComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
